import React, { useContext, useEffect, useRef, useState } from 'react'
import style from './index.module.scss'
import { getIDFromKeyword } from '../../service'
import { useCompanyDetail } from '../../DataProvider'
import Link from 'components/Link'
import { useParams } from 'next/navigation'
import Image from 'next/image'
import useMediaQuery from '@mui/material/useMediaQuery'
import { languageContext } from 'app/components/providers/languageProvider'
import Empty from 'app/components/empty/empty'
import { useFirstRender } from 'helpers/useFirstRender'
import MaterialButton from 'components/MaterialButton'
import MaterialBasicSelect from '../Select/MaterialBasicSelect'
import SalarySelect from 'app/components/commons/salarySelect'
import { cloneDeep } from 'lodash-es'
import { getSalaryRange } from 'helpers/salary'
import { Salary } from '../Salary'
import { JobsTag } from '../SearchPanel'
import { fetchCompanyJobs, v2CanSearchFetchCompanyJobs } from '../../../service'
import { convertClientToBackendLang } from 'helpers/country.base'
import { getJobLocation } from '../../helper/getJobLocation'
import Multiple from './Select'
import theme from './theme.js'
import { ThemeProvider } from '@mui/material/styles'
import Single from 'app/components/commons/select/single'
import AutorenewIcon from '@mui/icons-material/Autorenew'
import { Global, css } from '@emotion/react'

const GlobalStyle = css`
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
    margin-top: 7px;
  }
`

interface Props {
  functions: any[]
  xpLvlsList: any
  salaryList: any
  setOpen: (Boolean) => void
  containerHight: string
  addJobsTableTouch: any
  salaryTypeLists: any
  dailySalaryRangeFilters: any
  hourlySalaryRangeFilters: any
  annuallySalaryRangeFilters: any
}

const SearchIcon = () => {
  return (
    <span className={style.job_prefix}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='20'
        height='21'
        viewBox='0 0 20 21'
        fill='none'
      >
        <path
          d='M8.75 15.511C12.2018 15.511 15 12.7128 15 9.26105C15 5.80928 12.2018 3.01105 8.75 3.01105C5.29824 3.01105 2.5 5.80928 2.5 9.26105C2.5 12.7128 5.29824 15.511 8.75 15.511Z'
          stroke='#B8B8B8'
          strokeWidth='1.5'
          strokeLinejoin='round'
        />
        <path
          d='M13.3333 13.8444L16.6666 17.1777'
          stroke='#B8B8B8'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </span>
  )
}
const DownIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='11' height='7' viewBox='0 0 11 7' fill='none'>
    <path
      d='M0.938965 0.907986L5.40409 5.42093L9.67375 0.601135'
      stroke='#414141'
      strokeLinecap='round'
    />
  </svg>
)

export const SearchPanelMobile = (props: Props) => {
  const contextLang = useContext(languageContext)
  const { overview } = contextLang.companyDetail
  const { containerHight, addJobsTableTouch, salaryTypeLists } = props
  const { jobs, lang, dictionary } = useCompanyDetail()
  const { company_jobs } = jobs as any
  const { function_filter, job_type_filter, xp_lvl_filter, degree_filter } = company_jobs

  const {
    searchForJobTitleOrCompanyName,
    salary,
    experience,
    Industry,
    JobType,
    resetFilters,
    qualification: langQualification
  } = dictionary?.myJobs || {}
  const { search } = dictionary

  console.log(jobs, 'jobs')

  const params = useParams()
  const [jobsData, setJobsData] = useState(company_jobs?.jobs || [])
  const [pagination, setPagination] = useState({
    page: 1,
    total: company_jobs?.total_page
  })

  useEffect(() => {
    return addJobsTableTouch('jobs-card-list')
  }, [])

  // New Search
  const [jobFunction, setJobFunction] = useState(String(function_filter?.[0].id))
  const [workExperience, setWorkExperience] = useState([])
  const [qualification, setQualification] = useState([])
  const [jobTypes, setJobTypes] = useState([])
  // New Search options
  const jobFunctionList = function_filter.map((item) => {
    return { label: item.value, value: String(item.id) }
  })
  const workExperienceList = xp_lvl_filter.map((item) => {
    return { label: item.value, value: String(item.id) }
  })
  const qualificationList = degree_filter.map((item) => {
    return { label: item.value, value: String(item.id) }
  })
  const jobTypeList = job_type_filter.map((item) => {
    return { label: item.value, value: String(item.id) }
  })

  const inputText = useRef('')
  const [, setSearchValue] = useState('')
  const [loading, setLoading] = useState(false)
  const id = getIDFromKeyword(params.keyword as any)
  const firstRender = useFirstRender()
  const pageRef = useRef(1)
  const [searchParams, setSearchParams] = useState<any>({
    // companyIds: id,
    size: 10,
    page: 1,
    query: inputText.current,
    // location: location?.id,
    job_function_id: '',
    //  salary: '',
    xp_lvl_id: ''
  })
  const [salaries, setSalaries] = useState({
    salary: [],
    salaryType: []
  })

  const scrollRef = useRef(null)
  const unshiftArr = (arr = []) => {
    arr?.unshift({
      label: overview.All,
      id: ''
    })
    return arr
  }

  useEffect(() => {
    if (!props.functions) return
    // filterTagView.current = [{}].concat(props.functions);
  }, [props.functions])

  // useEffect(() => {
  //   if (firstRender) return
  //   searchFunc()
  // }, [searchParams])

  useEffect(() => {
    if (firstRender) return
    pageRef.current = 1
    searchFunc(null, 1, jobFunction, workExperience, qualification, jobTypes)
  }, [jobFunction, workExperience, qualification, jobTypes])

  // useEffect(() => {
  //   if (firstRender) return
  //   searchFunc()
  // }, [salaries])

  const searchFunc = (
    jobTitle?: string,
    page = 1,
    job_function_ids?: any,
    workExperience?: any,
    qualification?: any,
    jobTypes?: any
  ) => {
    setLoading(true)
    const reqData = {
      companyIds: id,
      size: 10,
      page,
      query: jobTitle || inputText.current,
      // location_id: location?.id,
      job_function_id: job_function_ids ? String(job_function_ids) : null,
      xp_lvl_ids: workExperience,
      degree_ids: qualification,
      job_type_ids: jobTypes
    }
    Object.keys(reqData).forEach((key) => {
      if (reqData[key] === null || reqData[key] === undefined) {
        delete reqData[key]
      }
    })

    v2CanSearchFetchCompanyJobs(id, convertClientToBackendLang(lang), reqData)
      .then((res) => {
        const data = res.company_jobs
        if (reqData.page > 1) {
          setJobsData((pre) => {
            return [...pre, ...(data.jobs || [])]
          })
        } else {
          setJobsData(data.jobs || [])
        }
        // console.log('company_hot_job', res)
        setPagination({
          page: reqData.page,
          total: res.company_jobs?.total_page
        })
      })
      .catch((e) => {
        console.log(e)
        pageRef.current--
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      pageRef.current = 1
      // setSearchParams({
      //   ...searchParams,
      //   query: inputText.current,
      //   page: 1
      // })
      pageRef.current = 1
      searchFunc(null, 1, jobFunction, workExperience, qualification, jobTypes)
      event?.target?.blur?.()
      // searchFunc(null, location)
    }
  }

  return (
    <div className={style.search_container}>
      <div className={style.search_input_wrapper}>
        <div className={style.search_input_layout}>
          <label htmlFor='input-search' className={style.job_search_container}>
            <SearchIcon />
            <input
              value={inputText.current}
              id={'input-search'}
              name={'input-search'}
              enterKeyHint='search'
              placeholder={overview.SearchPlaceholder}
              className={style.job_search_input}
              onChange={(e) => {
                inputText.current = e.target.value
                setSearchValue(e.target.value)
                // searchFunc(e.target.value,location)
              }}
              onKeyDown={handleKeyPress}
            />
          </label>
        </div>
      </div>

      <Global styles={GlobalStyle} />
      <ThemeProvider theme={theme}>
        <div className={style.filters}>
          <Single
            options={jobFunctionList}
            value={jobFunction}
            onSelect={setJobFunction}
            className={style.filterItems}
            label={overview.jobFunction}
          />

          <Multiple
            label={experience}
            value={workExperience}
            className={style.filterItems}
            options={workExperienceList}
            onSelect={setWorkExperience}
            defaultValue={workExperience}
          />

          <Multiple
            label={langQualification}
            value={qualification}
            className={style.filterItems}
            options={qualificationList}
            onSelect={setQualification}
            defaultValue={qualification}
          />

          <Multiple
            label={JobType}
            value={jobTypes}
            className={style.filterItems}
            options={jobTypeList}
            onSelect={setJobTypes}
            defaultValue={jobTypes}
          />

          <div
            className={style.resetFilters}
            onClick={() => {
              setJobFunction(String(function_filter?.[0].id))
              setWorkExperience([])
              setQualification([])
              setJobTypes([])
              inputText.current = ''
            }}
          >
            <AutorenewIcon /> {search.reset}
          </div>
        </div>
      </ThemeProvider>

      <div className={style.select}>
        {/* <Multiple 
          label={overview.jobFunction}
          value={companySizes}
          // className={styles.filterItems}
          options={unshiftArr(jobFunctions)}
          onSelect={setCompanySizes}
          defaultValue={companySizes}
        /> */}

        {/* <MaterialBasicSelect
          label={overview.jobFunction}
          value
          options={unshiftArr(jobFunctions)}
          onSelect={(e) => {
            pageRef.current = 1
            setSearchParams({
              ...searchParams,
              job_function_id: e,
              page: 1
            })
          }}
        ></MaterialBasicSelect> */}

        {/* <SalarySelect
          id='SalarySelect'
          key='SalarySelect'
          salaryTypeLists={props.salaryTypeLists}
          label={contextLang.search?.salary}
          value={salaries}
          lang={contextLang.search}
          className={style.filterItems}
          onChange={setSalaries}
          initHeight={'calc(80vh'}
          Icon={<DownIcon />}
        /> */}

        {/* <MaterialBasicSelect
          label={overview.WorkExperience}
          onSelect={(e) => {
            pageRef.current = 1
            setSearchParams({
              ...searchParams,
              xp_lvl_id: e,
              page: 1
            })
          }}
          options={xpLvlsList}
        ></MaterialBasicSelect> */}
      </div>

      <div className={style.lineBox}></div>
      <div
        className={style.search_content_wrapper}
        style={{ height: `calc(${containerHight} - 215px)` }}
        ref={scrollRef}
        id='jobs-card-list'
      >
        <div className={style.content_layout}>
          {!!jobsData?.length ? (
            jobsData.map((item, index) => {
              return (
                <JobsSearchCard
                  key={item.job_title + item.id + index}
                  item={item}
                  isOnline={Boolean(item.recruiter.is_online)}
                  {...item}
                />
              )
            })
          ) : (
            <div className={style.noData}>
              <Empty lang={contextLang.search} description={null} />
            </div>
          )}
        </div>
        <div className={style.pagination}>
          {(Math.ceil(pagination?.total) > pageRef.current || loading) && (
            <MaterialButton
              variant='contained'
              sx={{
                height: '44px',
                borderRadius: '4px',
                width: '144px',
                textTransform: 'capitalize !important',
                background: '#ffffff',
                boxShadow: 'none',
                border: '1px solid #2378E5',
                color: '#2378E5',
                fontSize: '14px',
                fontWeight: 'normal',
                ':hover': {
                  backgroundColor: '#fff',
                  boxShadow: 'none'
                },
                '-loading': {
                  border: 'none'
                }
              }}
              isLoading={loading}
              onClick={() => {
                // pageRef.current++
                // setSearchParams({
                //   ...searchParams,
                //   page: pageRef.current
                // })

                searchFunc(
                  null,
                  ++pageRef.current,
                  jobFunction,
                  workExperience,
                  qualification,
                  jobTypes
                )
              }}
            >
              {contextLang?.home?.seeMoreBtn}
            </MaterialButton>
          )}
        </div>
      </div>
    </div>
  )
}

const MobileJobTag = ({ data, isMobile }) => {
  return (
    <>
      {data
        .map((value, index) => {
          if (!value) return null
          return (
            <span className={style.mobile_tag} key={index}>
              {value}
            </span>
          )
        })
        .slice(0, 3)}
      {!isMobile && <JobsTag data={data} />}
    </>
  )
}

export const JobsSearchCard = (props: any) => {
  const { lang, dictionary } = useCompanyDetail()
  const isMobile = useMediaQuery('(max-width: 768px)')
  const contextLang = useContext(languageContext)
  // const dic = useLanguage()
  const { overview } = contextLang.companyDetail
  const { item, salary_info, isOnline } = props
  const locations = getJobLocation(item)

  return (
    <div className={style.search_card}>
      <div className={style.search_title_layout}>
        <Link
          href={'/' + lang + item.job_url}
          target='_blank'
          title={item.job_title}
          className={style.title}
        >
          {item.is_urgent && (
            <span className={style.urgentLabel}>{dictionary?.search?.urgent}</span>
          )}

          {item.is_remote_job && (
            <span className={style.urgentLabel}>{dictionary?.search?.remote}</span>
          )}
          <span style={{ verticalAlign: 'bottom' }}>{item.job_title}</span>
        </Link>
        <div className={style.jobcard_salary_wrapper}>
          <div className={style.salary}>
            <Salary salaryType={salary_info.type_text} salaryRange={salary_info.text} />
          </div>
          <Link className={style.chat_now} href={'/' + lang + item.job_url} target='_blank'>
            {overview.jobs.card.chatNow}
          </Link>
        </div>
      </div>

      <div className={style.content}>
        <MobileJobTag data={item.job_tags} isMobile={isMobile} />
      </div>
      <div className={style.footer}>
        <div className={style.chat_footer}>
          <div className={style.avatar}>
            <Image fill src={item?.recruiter?.avatar} alt='img' />
            <div
              className={style.status}
              style={{
                backgroundColor: item?.recruiter.is_online || isOnline ? '#0ebd5c' : '#E5E6EB'
              }}
            />
          </div>
          <Link className={style.name} href={'/' + lang + item.job_url} target='_blank'>
            <span title={item?.recruiter?.full_name}>{item?.recruiter?.full_name}</span>
            {item?.recruiter?.job_title && (
              <>
                &nbsp;<div style={{ position: 'relative', top: -2 }}>.</div>&nbsp;
                <span title={item?.recruiter?.job_title}>{item?.recruiter?.job_title}</span>
              </>
            )}
          </Link>
        </div>
        <div className={style.location} title={locations}>
          {locations}
        </div>
      </div>
    </div>
  )
}
